<template>
  <!-- <div class="top-tip">
    <div class="top-tip-mynote">MyNote</div>
  </div>
  <Task msg="Welcome to Your Vue.js App"/> -->
  <el-container class="whole-view">
    <el-header class="top-tip">
      <!-- <img src="https://www.mynotekid.com/favicon.png"/> -->
      <div class="top-tip-mynote">记事小本</div>
    </el-header>
    <el-container>
      <el-aside v-if="logined" width="260px"><LeftMenu/></el-aside>
      <el-main style="padding:0px;">
        <div v-if="!logined" class="login-view">
          <div class="login-view-form">
            <Login :loginCallback="setLogin"></Login>
          </div>
        </div>
        <router-view v-else class="center-css"></router-view>
      </el-main>
    </el-container>
    <el-footer><a href="https://beian.miit.gov.cn">浙ICP备18047692号-5</a></el-footer>
  </el-container>

</template>

<script>
import LeftMenu from './components/task/LeftMenu.vue'
import Login from './components/login/Login.vue'
import {getToken, setToken} from '@/api/global.js'
import {axregisterLogin} from '@/api/api.js'

export default {
  name: 'App',
  components: {
    LeftMenu,
    Login,
  },
  data() {
    return {
      logined: false,
      token: null,
    }
  },
  created() {
    axregisterLogin(this.getNeedLoginFunc())
    console.log("created token:", getToken())
  },
  methods: {
    setLogin(token) {
      console.log("setLogin callback token!", token)
      setToken(token)
      this.logined = true
    },
    getNeedLoginFunc() {
      let t = this
      return ()=>{
        t.logined = false
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif,”宋体”;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */
  /* padding:0px; */
  /* left:0px; */
}

body {
  margin: 0px;
  overflow-y: hidden;
}
.whole-view{
  height:100vh;
}
.top-tip{
  background:#0078D7;
  height:50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.top-tip-mynote{
  margin-left:40px;
  color:white;
  font-size: 1.1rem;
}

.center-css{
  display:flex;
  flex: 1 1 auto;
  background: #F8F9F9;
  /* overflow-y: hidden; */
  height: calc(100vh - 50px);
}
.login-view{
  display:flex;
  /* flex-direction: column; */
  justify-content: flex-end;
  min-height:100%;
}
.login-view-form{
  display:flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  background: #F8F9F9;
  width:300px;
  padding:20px;
}

.row {
  display: flex;
  align-items: center;
}

.row-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.row-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.row-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
  /* justify-content: ; */
  align-items: center;
}
.column-end {
  display: flex;
  flex-direction: column;
  /* justify-content: ; */
  align-items: flex-end;
}

</style>
