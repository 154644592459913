<template>
    <el-input v-model="userName" maxlength="50" placeholder="请输入用户名" class="item-css"></el-input>
    <el-input v-model="userPasswd" minlength="8" placeholder="请输入密码" class="item-css" show-password></el-input>
    <el-button type="primary" @click="login" class="item-css">登陆</el-button>
</template>

<script>
import {axpost} from '@/api/api.js'

export default {
  props: {
    loginCallback: Function,
  },
  data() {
    return {
      userName: "",
      userPasswd: "",
    }
  },
  components: {
  },
  created() {
  },
  methods: {
    async login() {
      let loginType = this.userName.indexOf("@") > 0 ? 3 : 2
      let res = await axpost('/api/user/login', {
        phoneID: this.userName,
        email: this.userName,
        loginPass: this.userPasswd,
        loginType: loginType,
      })
      console.log("login: !", res)
      // console.log("token: !", this.getCookie("JSESSIONID"))
      
      if (!res || res.data.ret) {
        // error
        console.log("login error!", res)
      } else {
        this.loginCallback(res.data.token)
      }
    },
    // getCookie(cname) {
    //   var name = cname + "=";
    //   var ca = document.cookie.split(';');
    //   for (var i = 0; i < ca.length; i++) {
    //       var c = ca[i];
    //       while (c.charAt(0) == ' ') c = c.substring(1);
    //       if (c.indexOf(name) != -1) {
    //           return c.substring(name.length, c.length);
    //       }
    //   }
    //   return "";
    // },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.item-css {
  margin:10px 0 10px 0;
}
</style>
