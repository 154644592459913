import { createApp } from 'vue'

////////////////////
// add by lkp
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import mitt from 'mitt'
//
//////////////////
import router from './router/router.js'
import App from './App.vue'
const app = createApp(App, router)

const emitter = mitt();
app.config.globalProperties.$mybus = emitter;

app.use(ElementPlus, {
  locale: locale,
})
app.use(router);

app.mount('#app')
