import axios from 'axios';
import { ElMessage } from 'element-plus'

let needLoginFunc
export async function axget(url, tip) {
  let res
  try {
    res = await axios.get(url)
    // 需要登陆
    if (res && res.data && res.data.ret == -999){
      // no login, jump to login page
      needLoginFunc()
      return
    }
    // 返回无值，表示错误
    if (!res || !res.data) {
      if (tip && tip.failMsg) {
        ElMessage.error(tip.failMsg);
      }
      return
    }
    // 返回有错误值
    if (res && res.data && res.data.ret){
      if (tip && tip.failMsg) {
        ElMessage.error(tip.failMsg+", " + res.data.errMsg);
      }
      return
    }
    // 成功提示
    if (tip && tip.successMsg) {
      ElMessage.success({
        message: tip.successMsg,
        type: 'success'
      });
    }
  } catch(e) {
    console.log("get exception:", e)
    ElMessage.error("操作异常！");
  }
  return res
}

export async function axpost(url, req, tip) {
  let res
  try {
    res = await axios.post(url, req)
    // console.log(res)
    // 需要登陆
    if (res && res.data && res.data.ret == -999){
      // no login, jump to login page
      needLoginFunc()
      return
    }
    // 返回无值，表示错误
    if (!res || !res.data) {
      if (tip && tip.failMsg) {
        ElMessage.error(tip.failMsg);
      }
      return
    }
    // 返回有错误值
    if (res && res.data && res.data.ret){
      if (tip && tip.failMsg) {
        ElMessage.error(tip.failMsg+", " + res.data.errMsg);
      }
      return
    }
    // 成功提示
    if (tip && tip.successMsg) {
      ElMessage.success({
        message: tip.successMsg,
        type: 'success'
      });
    }
  } catch(e) {
    console.log("post exception:", e)
    ElMessage.error("操作异常！");
  }
  return res
}

export function axok(res) {
  if (!res || !res.data || res.data.ret) {
    return false
  }
  return true
}

export function axregisterLogin(f) {
  needLoginFunc = f
}

// 0<=date-今天0点 <24小时  今天
// -24小时<=date-今天0点 <0  昨天
// 24小时<=date-今天0点 <48小时  明天
// export function formatDate(date) {
//   const mistiming = Math.round((Date.now() - new Date(date).getTime()) / 1000)
//   const tags = ['年', '个月', '星期', '天', '小时', '分钟', '秒']
//   const times = [31536000, 2592000, 604800, 86400, 3600, 60, 1]
//   for (let i = 0; i < times.length; i++) {
//       const inm = Math.floor(mistiming / times[i])
//       if (tags[i] === '天') {
//           switch (inm) {
//               case 0:
//                   return '今天'
//                   break
//               case 1:
//                   return '昨天'
//                   break
//               case 2:
//                   return '前天'
//                   break
//               default:
//                   return inm + tags[i] + '前'
//                   break;
//           }
//       }
//       if (inm !== 0) {
//           return inm + tags[i] + '前'
//       }
//   }
// }

// 0<=date-今天0点 <24小时  今天
// -24小时<=date-今天0点 <0  昨天
// 24小时<=date-今天0点 <48小时  明天
export function formatPlanDate(str) {
  if (!str || str[0]=='0') {
    return null
  }
  let dt = new Date(str)
  // console.log("Date.parse：", Date.parse('2015-06-24T19:49:22.875+08:00'))
  let ret = {tip:'', diff:0}
  // const diff = Math.floor((dt.getTime() + 8 * 3600 * 1000) / (24 * 3600 * 1000)) - Math.floor(Date.now() / (24 * 3600 * 1000))
  let d1 = Math.floor((dt.getTime()-dt.getTimezoneOffset()*60*1000) / (24 * 3600 * 1000))
  let d2 = Math.floor((Date.now()-dt.getTimezoneOffset()*60*1000) / (24 * 3600 * 1000))
  const diff = d1 -d2
  // const diff = Math.floor((dt.getTime()-date1.getTimezoneOffset()*60*1000) / (24 * 3600 * 1000)) - Math.floor((Date.now()-date1.getTimezoneOffset()*60*1000) / (24 * 3600 * 1000))
  if (diff == 0) {
    ret.tip= '今天'
  } else if (diff == -1) {
    ret.tip = '昨天'
  } else if (diff == 1) {
    ret.tip = '明天'
  } else {
    ret.tip = (dt.getMonth() + 1) + '/' + dt.getDate()
  }
  // console.log('input str: ', str, ", dt: ", dt, "now: ", Date.now(), ", diff: ", diff, ', ret.tip: ', ret.tip)
  ret.diff = diff
  return ret
}

export function formatWeekDate(str) {
  if (!str || str[0]=='0') {
    return null
  }
  let dt = new Date(str)
  let now = new Date()

  let ret = {tip:'', diff:0}
  let d1 = Math.floor((dt.getTime()-dt.getTimezoneOffset()*60*1000) / (24 * 3600 * 1000))
  let d2 = Math.floor((now-dt.getTimezoneOffset()*60*1000) / (24 * 3600 * 1000))
  const diff = d1 - d2

  let diffWeek = calcWeekDiff(now, dt)
  if (diff == 0) {
    ret.tip= '今天'
  } else if (diffWeek==-1) {
    ret.tip = "上周"+"日一二三四五六".charAt(dt.getDay())
  } else if (diffWeek==0) {
    ret.tip = "周"+"日一二三四五六".charAt(dt.getDay())
  } else if (diffWeek==1) {
    ret.tip = "下周"+"日一二三四五六".charAt(dt.getDay())
  } else {
    ret.tip = (dt.getMonth() + 1) + '/' + dt.getDate()
  }
  // if (diff == 0) {
  //   ret.tip= '今天'
  // } else if (diff == -1) {
  //   ret.tip = '昨天'
  // } else if (diff == 1) {
  //   ret.tip = '明天'
  // } else {
  //   ret.tip = (dt.getMonth() + 1) + '.' + dt.getDate()
  // }
  // console.log('input str: ', str, ", dt: ", dt, "now: ", Date.now(), ", diff: ", diff, ', ret.tip: ', ret.tip)
  ret.diff = diff
  return ret
}
function calcWeekDiff(timeStampA, timeStampB) {
  let A = new Date(timeStampA).setHours(0, 0, 0, 0);
  let B = new Date(timeStampB).setHours(0, 0, 0, 0);
  var oneDayTime = 1000 * 60 * 60 * 24;
  var old_count = parseInt(A / oneDayTime);
  var now_other = parseInt(B / oneDayTime);
  return parseInt((now_other + 4) / 7) - parseInt((old_count + 4) / 7);
}
// function isSameDay(timeStampA, timeStampB) {
//   let A = new Date(timeStampA).setHours(0, 0, 0, 0);
//   let B = new Date(timeStampB).setHours(0, 0, 0, 0);
//   var oneDayTime = 1000 * 60 * 60 * 24;
//   var old_count = parseInt(A / oneDayTime);
//   var now_other = parseInt(B / oneDayTime);
//   return parseInt(old_count) == parseInt(now_other);
// }

// 0<=date-今天0点 <24小时  今天
// -24小时<=date-今天0点 <0  昨天
// 24小时<=date-今天0点 <48小时  明天
export function formatDateRange(begin, end) {
  if (begin == 0) {
    return {tip:"", diff:0}
  }
  // let b, e
  // if (isSameDay(begin, end) || end == 0) {
  //   b = formatPlanDate(begin)
  //   e = formatPlanDate(end)
  // } else {
  //   b = formatWeekDate(begin)
  //   e = formatWeekDate(end)
  // }
  let b = formatWeekDate(begin)
  let e = formatWeekDate(end)

  // console.log(begin, b, b.tip + '~' + e.tip)
  let ret = {}
  if (end == 0 || b.tip==e.tip) {
    ret = {tip: b.tip, diff: b.diff}
  } else {
    ret = {tip: b.tip + ' ~ ' + e.tip, diff: e.diff}
  }
  return ret
}

export function isToday(str) {
  if (new Date(str).toDateString() === new Date().toDateString()) {
      return true
  }
  return false
}

export function nowStr() {
    let now = new Date()
    let year = now.getFullYear();
    let month = now.getMonth() + 1;
    let date = now.getDate();
    let hour = now.getHours();
    let minute = now.getMinutes();
    let second = now.getSeconds();
    return year + "-" + month + "-" + date + " " + hour + ":" + minute + ":" + second;
}

function fill0(n) {
  if (n < 10) {
    return '0'+n
  }
  return n
}

export function getYearWeek(dt) {
  /*  
  date1是当前日期  
  date2是当年第一天  
  d是当前日期是今年第多少天  
  用d + 当前年的第一天的周差距的和在除以7就是本年第几周  
  */
  if (!dt) {
    return 0
  }
  var date1 = new Date(dt),
      date2 = new Date(date1.getFullYear(), 0, 1),
      d = Math.round((date1.valueOf() - date2.valueOf()) / 86400000)
  return Math.ceil((d + ((date2.getDay() + 1) - 1)) / 7)
}

function formatNumber(n) {
  n = n.toString()
  return n[1] ? n : `0${n}`
}
export function getDateHHMI(d) {
  let dt = new Date(d)
  return formatNumber(dt.getHours())+':'+formatNumber(dt.getMinutes())
}
export function getDateStr(d) {
  let dt = new Date(d)

  return dt.getFullYear() + "/" + (dt.getMonth() + 1) + "/" + (dt.getDate())
  // return dt.getFullYear() + "/" + (dt.getMonth() + 1) + "/" + (dt.getDate()) + " " + dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds()
}
export function getDateTimeStr(d) {
  let dt = new Date(d)

  return dt.getFullYear() + "/" + (dt.getMonth() + 1) + "/" + (dt.getDate()) + 
    " " + formatNumber(dt.getHours()) + ":" + formatNumber(dt.getMinutes())
}

export function dtToLocalStr(v) {
  if (!v) {
    return
  }
  let dt = new Date(v)
  let year = dt.getFullYear();
  let month = dt.getMonth() + 1;
  let date = dt.getDate();
  let hour = dt.getHours();
  let minute = dt.getMinutes();
  let second = dt.getSeconds();
  return year + "-" + fill0(month) + "-" + fill0(date) + " " + fill0(hour) + ":" + fill0(minute) + ":" + fill0(second)
}
