
let token
let queryState = {
  noteID: 1
}
// let logined = false

export function getToken() {
  return token
}
export function setToken(t) {
  token = t
}

export function getQueryState() {
  return queryState
}

export function setQueryState(s) {
  queryState = s
}

let globalData={
  todayNoteID:1,
  isTodayNote:function(noteID) {
    return noteID==1
  }
}
export {globalData}
