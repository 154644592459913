<template>
  <el-row class="center-content">
    <!-- <el-row class="tac"> -->
    <el-col class="left-view-css">
      <el-menu
        default-active="task-item-0"
        class="menu-css el-menu-vertical-demo"
        background-color="#f4f4f4"
        @select="switchTask">
        <el-sub-menu index="task-1">
          <template #title>
            <i class="el-icon-location"></i>
            <span>待办</span>
            <span>
              <el-dropdown @command="triggerNoteManager" trigger="hover">
                <i class="el-icon-more" size="5" style="padding:5px 20px 5px 20px; font-size:0.8rem; color:#797D7F;" @click.stop="{}"></i>
                <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="addNote">添加新分类</el-dropdown-item>
                  <el-dropdown-item command="editNote">修改分类名称</el-dropdown-item>
                  <el-dropdown-item command="deleteNote">删除选中分类</el-dropdown-item>
                  <el-dropdown-item command="hideNote">隐藏选中分类</el-dropdown-item>
                  <el-dropdown-item command="dispalyNote">显示其他分类</el-dropdown-item>
                </el-dropdown-menu>
              </template>
              </el-dropdown>
            </span>
          </template>
          <!-- <el-menu-item v-for="(note, noteIndex) in noteInfos" :key="note.noteID" :index="'task-item-'+noteIndex">
            <draggable v-model="noteInfos"  chosenClass="chosen" forceFallback="true" group="people" animation="1000"  
              @start="onDragStart" @end="onDragEnd" @move="onDragMove" item-key="index">
              <template #item="{ element }">
                <span class="note-item">
                  <span>{{element.noteName}}</span>
                  <span>{{element.todealCount}}</span>
                </span>
              </template>
            </draggable>
          </el-menu-item>
           -->
            <!-- <draggable v-model="noteInfos"  chosenClass="chosen" forceFallback="true" group="people" animation="1000" @start="onDragStart" @end="onDragEnd" @move="onDragMove" item-key="index">
              <template #item="{ element, index }">
                <el-menu-item v-if="element.noteName" :index="'task-item-'+index">
                  <span class="note-item">
                    <span>{{element.noteName}}</span>
                    <span>{{element.todealCount}}</span>
                  </span>
                </el-menu-item>
              </template>
            </draggable> -->
          <el-menu-item v-for="(note, noteIndex) in noteInfos" :key="note.noteID" :index="'task-item-'+noteIndex">
            <div class="note-item" @mouseenter="noteMouseEnter(note)" @mouseleave="noteMouseLeave()">
              <span>{{note.noteName}}</span>
              <span>{{note.todealCount}}</span>
            </div>
          </el-menu-item>

          <!-- <el-menu-item index="1-1">今天</el-menu-item>
          <el-menu-item index="1-2">工作</el-menu-item>
          <el-menu-item index="1-3">家庭</el-menu-item>
          <el-menu-item index="1-4">个人</el-menu-item> -->
        </el-sub-menu>
        <el-menu-item index="memo-2">
          <i class="el-icon-menu"></i>
          <template #title>备忘</template>
        </el-menu-item>
        <el-menu-item index="3">
          <i class="el-icon-document"></i>
          <template #title>日记</template>
        </el-menu-item>
      </el-menu>
    </el-col>

    <el-dialog
      v-model="newNoteVisible"
      width="30%">
      <el-input v-model="newNoteName" placeholder="请输入新的名称" clearable></el-input>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="newNoteVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirmNoteManagerCmd">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    
  </el-row>
</template>

<script>
import {axpost} from '@/api/api.js'
import { ElMessage } from 'element-plus'
// import draggable from 'vuedraggable'
import {globalData} from '@/api/global.js'

export default {
  data() {
    return {
      currNote: {},
      noteInfos: [{
        noteID: globalData.todayNoteID,
        noteName: '今天',
      }],
      newNoteName: "",
      newNoteVisible: false,
      currCmd: "",
    }
  },
  props: {
  },
  setup() {
  },
  components: {
    // draggable,
  },
  created() {
    this.$mybus.on('freshNoteInfos', e => {
      this.noteInfos = e
    });

    console.log("left menu created")
    this.$router.push('/task?noteID='+globalData.todayNoteID)
  },
  methods:{
    switchTask(item){
      let pos = item.indexOf("task-item-")
      if (pos >= 0) {
        let noteIndex = parseInt(item.substring(pos + 10))
        let note = this.noteInfos[noteIndex]
        console.log("switchTask", noteIndex, note)
        this.currNote = note
        this.$router.push('/task?noteID=' + note.noteID)
        return
      }
    },
    triggerNoteManager(cmd) {
      switch (cmd) {
      case "addNote":
        this.currCmd = "addNote"
        this.newNoteVisible = true
        break;
      case "editNote":
        if (this.currNote.noteID <= 0) {
          return
        }
        this.currCmd = "editNote"
        this.newNoteVisible = true
        break;
      case "deleteNote":
        if (this.currNote.noteID <= 0) {
          return
        }
        this.confirmOperate(this.deleteNote)
        break;
      case "hideNote":
        break;
      case "dispalyNote":
        break;
      }
    },
    confirmNoteManagerCmd() {
      switch (this.currCmd) {
      case "addNote":
        this.addNote()
        break;
      case "editNote":
        this.editNote()
        break;
      }
    },
    async addNote() {
      console.log(this.currNote)
      let res = await axpost('/api/todo/note/create', {
        noteName: this.newNoteName,
      })
      console.log("add note res:", res)
      if (!res || !res.data || res.data.ret) {
        ElMessage.error('新增分类失败');
        return
      }
      this.noteInfos = res.data

      ElMessage.success({
        message: '新增分类成功',
        type: 'success'
      });
      this.newNoteName = ""
      this.newNoteVisible = false
    },
    async editNote() {
      console.log(this.currNote)
      let res = await axpost('/api/todo/note/chgname', {
        noteID: parseInt(this.currNote.noteID),
        newName: this.newNoteName,
      })
      console.log("note chgname res:", res)
      if (!res || !res.data || res.data.ret) {
        ElMessage.error('修改名称失败');
        return
      }
      this.noteInfos = res.data
      ElMessage.success({
        message: '修改名称成功',
        type: 'success'
      });
      this.newNoteName = ""
      this.newNoteVisible = false
    },
    async deleteNote() {
      console.log(this.currNote)
      let res = await axpost('/api/todo/note/delete', {
        noteID: parseInt(this.currNote.noteID),
      })
      console.log("delete note res:", res)
      if (!res || !res.data || res.data.ret) {
        ElMessage.error('删除分类失败');
        return
      }
      this.noteInfos = res.data
      ElMessage.success({
        message: '删除分类成功',
        type: 'success'
      });
    },
    onDragStart() {
      console.log("onDragStart")
    },
    onDragEnd() {
      console.log("onDragEnd")
    },
    onDragMove(evt, originalEvent) {
      console.log(evt, originalEvent)
    },
    noteMouseEnter(note) {
      this.$mybus.emit('mouseOverNoteID', note.noteID);
    },
    noteMouseLeave() {
      this.$mybus.emit('mouseOverNoteID', 0);
    },
    confirmOperate(f) {
        this.$confirm('删除后将不能恢复, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            f()
          })
          .catch(() => {
          })
      },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.center-content{
  width: 100%;
  height:100%;
}
.left-view-css{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width:100%;
  text-align:left;
}

.menu-css{
  width:100%;
  height:100%;
}

.note-item {
  display: flex;
  justify-content: space-between;
}

.el-menu-item{
  padding-right: 22px;
}

/*被拖拽对象的样式*/
.item {
  padding: 6px;
  background-color: #fdfdfd;
  border: solid 1px #eee;
  margin-bottom: 10px;
  cursor: move;
} 
/*选中样式*/
.chosen {
  border: solid 2px #3089dc !important;
}
</style>
