<template>
  <el-row class="task-view">
    <el-col :span="17" style="height:100%;overflow-y:auto;">
      <div v-for="(group, groupIndex) in groupTasks" :key="groupIndex">
        <div :class="['group-row', groupIndex>0?'group-row-notfirst':'']" @click="expandState(group)">
          <div class="group-row-info">
            {{group.groupName}}({{group.totalCount}})
          </div>
          <div :class="group.expand?'unfold-css':'fold-css'">
          </div>
        </div>
        <div v-if="group.expand">
          <draggable v-model="group.tasks" delay="200" chosenClass="chosen" forceFallback="true" :group="'group-'+groupIndex" animation="1000" @start="onDragStart" @end="onDragEnd" :move="onDragMove" ghostClass="draged-item" item-key="taskID">
            <template #item="{ element }">
              <div :class="'one-task '+(currTask && element.taskID==currTask.taskID?'one-task-curr':(element.taskID==mouseOverTaskID?'one-task-mouseover':''))" 
                @click="selectTask(element)" @mouseenter="taskMouseEnter(element.taskID)" @mouseleave="taskMouseLeave()"
                :data-taskID="element.taskID" :data-groupIndex="groupIndex">
                <div class="one-task-left">
                  <div :class="['cycle', 'task-cycle-pos', element.taskStatus != groupIDDef.Done && element.starFlag?'task-cycle-star':'']" @click.stop="doneTask(element)">
                    <div v-if="hasTaskDone(element)" class="gou"/>
                  </div>
                  <!-- <div :class="['task-title-view', element.starFlag?'task-title-view-star':'']"> -->
                  <div class="task-title-view">
                    <div v-if="(queryParams.noteID) != noteIDDef.TodayNoteID && element.todayHHMI" class="today-tip">今</div>
                    {{element.taskTitle}}
                    <i v-if="element.hasContent" class="el-icon-document" size="5" style="margin-left:10px; font-size:0.5rem;"></i>
                  </div>
                </div>
                <div class="column-end one-task-info-tip">
                  <div class="one-task-info-endplan">
                    <div v-if="(queryParams.noteID) == noteIDDef.TodayNoteID && element.todayHHMI && isStatusTodo(element)" @click="showSetTodayHHMI(element.todayHHMI)" class="today-time-hand">
                      {{element.todayHHMI}}
                    </div>
                    <div v-if="(queryParams.noteID) != noteIDDef.TodayNoteID && element.planTip && element.planTip.tip && isStatusTodo(element)" 
                      :class="(element.planTip.diff<0?'one-task-info-tip-past':'')">
                      {{element.planTip.tip}}
                    </div>
                    <div v-if="(queryParams.noteID) != noteIDDef.TodayNoteID && element.doneTimeTip && element.doneTimeTip.tip && hasTaskDone(element)">
                      {{element.doneTimeTip.tip}}
                    </div>
                  </div>
                  <!-- <div>
                    <i v-if="element.hasContent" class="el-icon-document" size="5" style="margin-left:10px; font-size:0.5rem;"></i>
                  </div> -->
                  <!-- <div>
                    <i v-if="!element.starFlag" class="el-icon-star-off" style="padding:5px 1px 5px 10px; font-size:1rem; color:#797D7F;" @click.stop="setStar(element.taskID)"></i>
                    <i v-else class="el-icon-star-on" style="padding:5px 0 5px 10px; font-size:1.2rem; color:#3498DB;" @click.stop="setStar(element.taskID)"></i>
                  </div> -->
                </div>
              </div>
            </template>
          </draggable>
        </div>
        <div v-if="group.groupID == groupIDDef.ToDo" class="new-task-css">
          <el-input v-model="newTask" placeholder="+请输入新任务" clearable @keyup.enter="submitNewTask(group.taskStatus)"></el-input>
        </div>
        <div v-if="group.groupID == groupIDDef.Done && group.tasks.length < group.totalCount" class="row-center query-more">
          <el-button size="mini" style="background:#FFF;" round @click="queryMore(group.taskStatus)">查询更多</el-button>
        </div>
      </div>
    </el-col>
    <el-col :span="7" class="task-detail" :style="currTask&&currTask.taskID?'':'pointer-events:none'">
      <div class="group-row">任务详情</div>
      <div class="row task-detail-sub">
        <el-input v-model="taskTitle" @input="autoSaveTaskTitle" @keyup.enter="endEditTaskTitleByEnter"></el-input>
        <i v-if="!currTask.starFlag" class="el-icon-star-off" style="padding:5px 1px 5px 10px; font-size:1rem; color:#797D7F;" @click.stop="setStar(currTask.taskID)"></i>
        <i v-else class="el-icon-star-on" style="padding:5px 0 5px 10px; font-size:1.2rem; color:#F39C12;" @click.stop="setStar(currTask.taskID)"></i>
      </div>
      
      <div class="row task-detail-plan-row" @click="showSetBeginPlan">
        <div class="row">计划开始：
          <div :style="currTask.beginPlan ? (currTask.beginPlanTip.diff < 0 ?'color:red':'color:blue') : ''" >{{currTask.beginPlan ? currTask.beginPlanTip.tip : "无"}}</div>
          <el-date-picker
            v-model="newBeginPlan"
            type="datetime"
            ref="beginPlanComponent"
            placeholder="选择日期时间"
            :default-value="defaultBeginPlan"
            style="z-index:-1;width:0px;height:0px;left:-2rem;" @change="confirmBeginPlan">
          </el-date-picker>
        </div>
        <div v-if="currTask.beginPlan && currTask.beginPlanTip.tip" class="close-css" @click.stop="clearBeginPlan"></div>
      </div>
      <div class="row task-detail-plan-row" @click="showSetEndPlan">
        <div class="row">截至日期：
          <div :style="currTask.endPlan ? (currTask.endPlanTip.diff < 0 ?'color:red':'color:blue') : ''" >{{currTask.endPlan ? currTask.endPlanTip.tip : "无"}}</div>
          <el-date-picker
            v-model="newEndPlan"
            type="date"
            ref="endPlanComponent"
            placeholder="选择日期时间"
            :default-value="defaultEndPlan"
            style="z-index:-1;width:0px;height:0px;left:-2rem;" @change="confirmEndPlan">
          </el-date-picker>
        </div>
        <div v-if="currTask.endPlan && currTask.endPlanTip.tip" class="close-css" @click.stop="clearEndPlan"></div>
      </div>

      <div class="task-detail-sub">
        不重复
      </div>
      <div class="task-detail-sub">
        无提醒
      </div>
      <div class="task-detail-sub">
        <el-input type="textarea" :rows="5" placeholder="请输入任务详细描述" v-model="taskContent" @input="autoSaveTaskContent" 
          @blur="endEditTaskContent"></el-input>
      </div>
      <div class="task-detail-sub">
        归属于：{{currTask.noteName}}
      </div>
      <div class="task-detail-sub">
        创建于：{{currTask.createTimeStr}}
      </div>
      <div class="task-detail-sub">
        <el-button @click="delTask" size="small" round plain>删除任务</el-button>
      </div>

    </el-col>

    <el-dialog v-model="dialogSetTodayHHMIVisible" title="设置开始时间" width="20%" center>
      <el-time-select
        v-model="newTodayHHMI"
        placeholder=""
        start="00:00"
        step="00:15"
        end="23:59">
      </el-time-select>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogSetTodayHHMIVisible = false" size="small" style="margin-right: 40px;">取消</el-button>
          <el-button type="primary" @click="chgTodayHHMI" size="small">确认</el-button>
        </span>
      </template>
    </el-dialog>

  </el-row>
</template>

<script>
import {axpost, axok, formatWeekDate, getDateHHMI, dtToLocalStr} from '@/api/api.js'
import { ref } from 'vue'
// import { ElMessage } from 'element-plus'
import debounce from 'lodash.debounce'
import draggable from 'vuedraggable'
// import {globalData} from '@/api/global.js'

export default {
  data() {
    return {
      queryParams: {},
      currTask: {},
      mouseOverTaskID: 0,
      groupTasks:{tasks:[{taskID:111}]},
      taskTitle: "",
      taskTitleChanged: false,
      isSavingTaskTitle: false,
      taskContent: "",
      // newDonePlan: new Date(),
      mouseOverNoteID: 0,

      newBeginPlan: new Date(),
      newEndPlan: new Date(),
      defaultBeginPlan: new Date(),
      defaultEndPlan: new Date(),
      // newBeginPlanShow: false,
      // newEndPlanShow: false,

      newTodayHHMI: "",
      dialogSetTodayHHMIVisible: false,

      noteIDDef: {
        TodayNoteID: 1,
        InPlanNoteID: 2,
      },
      groupIDDef: {
        ToDo: 1,
        Done: 2,
        ThisWeek: 101,
      }
    }
  },
  components: {
    draggable,
  },
  setup() {
    return {
      newTask: ref(''),
    }
  },
  created() {
    // this.$watch(
    //   () => this.$route.params,
    //   async  (toParams, previousParams) => {
    //     // 对路由变化做出响应...
    //     this.noteID11 = toParams.noteID
    //     let res = await axpost('/task/query', {
    //       noteID: 1,
    //       taskType: 1,
    //       taskPlan: 1,
    //     })
    //     console.log("query res:", res)
    //     console.log("from: ", previousParams, ", to: ", toParams.noteID, ", task: ", this.noteID11)
    //   }
    // )

     this.$mybus.on('mouseOverNoteID', e => {
      this.mouseOverNoteID = e
    });

    this.freshTasks()
    // this.queryTasks(this.$route.query)
    this.queryParams = this.$route.query
    this.emptyCurr()
  },
  beforeRouteUpdate(to) {
    console.log("to:", to)
    this.queryParams = to.query
    this.emptyCurr()
    this.queryTasks(to.query)
    this.emptyCurr()

    // // 对路由变化做出响应...
    // const route = this.$route

    // this.noteID11 = route.query.noteID
    // let res = await axpost('/task/query', {
    //   noteID: parseInt(route.query.noteID),
    //   taskType: parseInt(route.query.taskType),
    //   taskPlan: parseInt(route.query.taskPlan),
    // })
    // console.log("query res:", res)
    // console.log("to", to.params.noteID, ", task: ", this.noteID11)
  },
  methods:{
    emptyCurr() {
      this.currTask = {}
      this.taskTitle = ""
      this.taskTitleChanged = false
      this.taskContent = ""
    },
    freshTasks() {
      this.queryTasks(this.$route.query)
    },
    async queryTasks(params, moreCond) {
      let queryParams = {
        noteID: parseInt(params.noteID),
        currTaskID: (this.currTask && this.currTask.taskID) ? this.currTask.taskID : 0,
        moreCond:moreCond,
      }
      // console.log("query queryParams:", queryParams)
      let res = await axpost('/api/todo/task/listbynote',
        queryParams,
        {
          failMsg: "查询出现错误！",
        })
      console.log("queryTasks res:", res)
      if (!axok(res)) {
        this.groupTasks = {}
        return
      }

      if (moreCond && moreCond.moreFlag) {
        let newTasks
        for (let i in res.data.groupTasks) {
          if (this.groupTasks[i].taskStatus == moreCond.taskStatus) {
            newTasks = res.data.groupTasks[i].tasks
            break
          }
        }
        for (let i in this.groupTasks) {
          if (this.groupTasks[i].taskStatus == moreCond.taskStatus) {
            this.groupTasks[i].tasks = this.groupTasks[i].tasks.concat(newTasks)
            let tasks = this.groupTasks[i].tasks
            // console.log("2222", tasks)
            for (let j = tasks.length - res.data.groupTasks.length; j < tasks.length; j++) {
              this.dealTaskAfterRes(tasks[j])
            }
            break
          }
        }
      } else {
        this.$mybus.emit('freshNoteInfos', res.data.noteInfos);
        
        this.groupTasks = res.data.groupTasks
        for (let i in this.groupTasks) {
          if (this.groupTasks[i].tasks) {
            let tasks = this.groupTasks[i].tasks
            for (let j = 0; j < tasks.length; j++) {
              this.dealTaskAfterRes(tasks[j])
            }
          }
        }
      }

      if (queryParams.currTaskID) {
        this.dealTaskAfterRes(res.data.currTask)
        this.setCurrTask(res.data.currTask)
        this.taskTitle = this.currTask.taskTitle
        this.taskContent = this.currTask.taskContent
      }
    },
    dealTaskAfterRes(task) {
      // task.planDateStr = getDateTimeStr(task.beginPlan) + (task.endPlan ? (' 到 ' + getDateStr(task.endPlan)): '')
      let beginPlanTip = formatWeekDate(task.beginPlan)
      let endPlanTip = formatWeekDate(task.endPlan)

      task.planTip = {}
      task.planTip.tip = (beginPlanTip?beginPlanTip.tip:'') + ((task.endPlan && beginPlanTip.tip != endPlanTip.tip)? (' 到 ' + endPlanTip.tip):'')
      task.planTip.diff = task.endPlan ? endPlanTip.diff : (task.beginPlan ? beginPlanTip.diff : 0)

      task.beginPlanTip = task.beginPlan ?{
        tip: beginPlanTip.tip + " " + getDateHHMI(task.beginPlan),
        diff: beginPlanTip.diff,
      } : {}
      task.endPlanTip = task.endPlan ? formatWeekDate(task.endPlan): ''

      // planTip.diff = task.endPlan ? endPlanTip.diff : task.beginPlanTip.diff
      // console.log('planTip: ', planTip, task, (task.endPlan && task.beginPlan < task.endPlan))
      task.doneTimeTip = formatWeekDate(task.doneTime)
    },
    queryMore(taskState) {
      let task
      for (let i in this.groupTasks) {
        if (this.groupTasks[i].taskStatus == taskState) {
          task = this.groupTasks[i].tasks[this.groupTasks[i].tasks.length-1]
          break
        }
      }
      console.log(task)
      this.queryTasks(this.$route.query, {
        moreFlag: true,
        taskState: taskState,
        doneTime: task.doneTime,
        doneOrder: task.doneOrder,
      })
    },
    async doneTask(task) {
      task.taskStatus = task.taskStatus==1?2:1
      let res = await axpost('/api/todo/task/chgstatus', {
        taskID: task.taskID,
        newStatus: task.taskStatus,
      },{
          failMsg: "完成操作失败",
      })
      console.log("doneTask res:", res)
      if (!axok(res)) {
        return
      }
      this.freshTasks()
    },
    expandState(group) {
      group.expand = !group.expand
    },
    async submitNewTask() {
      let currNote = this.$route.query
      console.log(currNote)
      let res = await axpost('/api/todo/task/create', {
          noteID: parseInt(currNote.noteID),
          taskTitle: this.newTask,
        },{
          failMsg: "添加失败",
          successMsg: "添加成功",
        })
      console.log("create res:", res)
      if (!axok(res)) {
        return
      }
      this.newTask = ""
      // this.queryTasks(currNote)
      this.freshTasks()
    },
    async selectTask(task) {
      this.currTask = task
      let res = await axpost('/api/todo/task/detail', {
          taskID: task.taskID,
        },{
          failMsg: "查询明细失败",
        })
      // console.log("detai res:", res)
      if (!axok(res)) {
        return
      }
      // this.currTask = res.data
      this.dealTaskAfterRes(res.data)
      this.setCurrTask(res.data)
    },
    setCurrTask(task) {
      this.currTask = task
      this.taskTitle = this.currTask.taskTitle
      this.taskContent = this.currTask.taskContent
      // this.newBeginPlan = new Date(this.currTask.beginPlan)
      // this.newEndPlan = new Date(this.currTask.endPlan)

      this.currTask.createTimeStr = dtToLocalStr(this.currTask.createTime)
    },
    confirmBeginPlan() {
      // this.chgSchedule(new Date(this.newBeginPlan).getTime(), this.currTask.endPlan)
      this.chgScheduleByType(1, new Date(this.newBeginPlan).getTime(), 0, "")
    },
    clearBeginPlan() {
      // this.chgSchedule(0, this.currTask.endPlan)
      this.chgScheduleByType(1, 0, 0, "")
    },
    confirmEndPlan() {
      let endDate = new Date(this.newEndPlan)
      let endPlan = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59)

      // this.chgSchedule(this.currTask.beginPlan, endPlan.getTime())
      this.chgScheduleByType(2, 0, endPlan.getTime(), "")
    },
    clearEndPlan() {
      // this.chgSchedule(this.currTask.beginPlan, 0)
      this.chgScheduleByType(2, 0, 0, "")
    },
    showSetTodayHHMI(v) {
      // this.newTodayHHMI = v
      // console.log("this.newTodayHHMI:", this.newTodayHHMI)
      this.dialogSetTodayHHMIVisible = true
      this.newTodayHHMI = v
    },
    chgTodayHHMI() {
      this.dialogSetTodayHHMIVisible = false
      let beginPlanTime = new Date(this.currTask.beginPlan)
      beginPlanTime.setHours(this.newTodayHHMI.substring(0, 2), this.newTodayHHMI.substring(3, 5), 0)
      // this.chgSchedule(beginPlanTime.getTime(), this.currTask.endPlan)
      this.chgScheduleByType(3, 0, 0, this.newTodayHHMI)
    },
    async chgScheduleByType(chgType, newBeginPlan, newEndPlan, newTodayHHMI) {
      if (!this.currTask) {
        return
      }
      let res = await axpost('/api/todo/task/chgschedule', {
          taskID: this.currTask.taskID,
          chgType: chgType,
          newBeginPlan: newBeginPlan,
          newEndPlan: newEndPlan,
          todayHHMI: newTodayHHMI,
        },{
          failMsg: "修改计划失败",
        })
      console.log("chgScheduleByType res:", res)
      if (!axok(res)) {
        return
      }
      this.freshTasks()
    },
    // async chgSchedule(beginPlan, endPlan) {
    //   if (!this.currTask) {
    //     return
    //   }
    //   let res = await axpost('/api/todo/task/chgschedule', {
    //       taskID: this.currTask.taskID,
    //       newBeginPlan: beginPlan,
    //       newEndPlan: endPlan,
    //     },{
    //       failMsg: "修改计划失败",
    //     })
    //   if (!axok(res)) {
    //     return
    //   }
    //   this.freshTasks()
    // },
    showSetBeginPlan() {
      // this.newBeginPlanShow = true
      if (this.currTask.beginPlan) {
        this.newBeginPlan = new Date(this.currTask.beginPlan)
      }
      this.defaultBeginPlan = this.currTask.beginPlan?new Date(this.currTask.beginPlan):new Date()
      // console.log("this.defaultBeginPlan:", this.currTask, this.defaultBeginPlan)
      this.$refs.beginPlanComponent.focus()
    },
    showSetEndPlan() {
      // this.newEndPlanShow = true
      if (this.currTask.endPlan) {
        this.newEndPlan = new Date(this.currTask.endPlan)
      }
      this.defaultEndPlan = this.currTask.endPlan?new Date(this.currTask.endPlan):new Date()
      // console.log("this.defaultEndPlan:", this.currTask, this.defaultBeginPlan)
      this.$refs.endPlanComponent.focus()
    },
    // showSetDonePlan() {
    //   this.$refs.endPlanComponent.focus()
    // },
    // async confirmDonePlan(flag) {
    //   // console.log('this.newDonePlan: ', this.newDonePlan)
    //   if (!this.currTask) {
    //     return
    //   }
    //   let res = await axpost('/api/todo/task/chgdoneplan', {
    //       taskID: this.currTask.taskID,
    //       newDonePlan: flag ? new Date(this.newDonePlan).getTime() : 0,
    //     },{
    //       failMsg: "修改计划完成日期时间失败",
    //     })
    //   console.log("chgDonePlan res:", res)
    //   if (!axok(res)) {
    //     return
    //   }
    //   this.freshTasks()
    // },
    // async showSetNextSchedule() {
    //   this.$refs.nextScheduleComponent.focus()
    // },
    // async confirmNextSchedule(flag) {
    //   // console.log('this.newDonePlan: ', this.newDonePlan)
    //   if (!this.currTask) {
    //     return
    //   }
    //   let res = await axpost('/api/todo/task/chgnextschedule', {
    //       taskID: this.currTask.taskID,
    //       newDonePlan: flag ? new Date(this.newDonePlan).getTime() : 0,
    //     },{
    //       failMsg: "修改计划完成日期时间失败",
    //     })
    //   console.log("confirmNextSchedule res:", res)
    //   if (!axok(res)) {
    //     return
    //   }
    //   this.freshTasks()
    // },
    async setStar(taskID) {
      console.log('setStar: ', taskID)
      let res = await axpost('/api/todo/task/setstar', {
          taskID: taskID,
        },{
          failMsg: "标星失败",
        })
      console.log("setStar res:", res)
      if (!axok(res)) {
        return
      }
      this.freshTasks()
    },
    endEditTaskTitle() {
      console.log("endEditTaskTitle")
      if (!this.taskTitleChanged) {
        return
      }
      this.taskTitleChanged = false
      this.freshTasks()
    },
    endEditTaskTitleByEnter() {
      if (!this.taskTitleChanged) {
        return
      }
      if (this.currTask && this.currTask.taskTitle != this.taskTitle) {
        console.log("endEditTaskTitleByEnter:", this.taskTitle)
        this.saveTaskTitle()
      }
      this.freshTasks()
    },
    async saveTaskTitle() {
      if (this.currTask.taskTitle == this.taskTitle || this.isSavingTaskTitle) {
        return
      }
      this.isSavingTaskTitle = true
      let res = await axpost('/api/todo/task/chgtitle', {
          taskID: this.currTask.taskID,
          newTitle: this.taskTitle,
        },{
            failMsg: "保存标题失败",
        })
      this.isSavingTaskTitle = false
      console.log("chg task title res:", res)
      if (!axok(res)) {
        return
      }
      this.currTask.taskTitle = this.taskTitle
    },
    autoSaveTaskTitle: debounce(function(val) {
      if (!this.currTask || !this.currTask.taskID) {
        return
      }
      console.log("autoSaveTaskTitle newVal:", val)
      this.taskTitleChanged = true
      this.saveTaskTitle()
      this.endEditTaskContent()
    }, 1000),
    autoSaveTaskContent: debounce(async function(val) {
      if (!this.currTask || !this.currTask.taskID || this.currTask.taskContent == this.taskContent) {
        return
      }
      console.log("autoSaveTaskContent newVal:", val)
      let res = await axpost('/api/todo/task/chgcontent', {
          taskID: this.currTask.taskID,
          newContent: val,
        },{
            failMsg: "保存任务详情失败",
        })
      console.log("chgcontent res:", res)
      if (!axok(res)) {
        return
      }
      this.currTask.taskContent = this.taskContent
      this.taskContentChanged = true
      this.endEditTaskContent()
    }, 1000),
    endEditTaskContent() {
      console.log("endEditTaskContent")
      if (!this.taskContentChanged) {
        return
      }
      this.taskContentChanged = false
      this.freshTasks()
    },
    async delTask() {
      if (!this.currTask || !this.currTask.taskID) {
        return
      }
      let res = await axpost('/api/todo/task/delete', {
          taskID: this.currTask.taskID,
        },{
            successMsg: "删除任务成功",
            failMsg: "删除任务失败",
        })
      console.log("chgplan res:", res)
      if (!axok(res)) {
        return
      }
      this.freshTasks()
      this.emptyCurr()
    },
    taskMouseEnter(taskID) {
      this.mouseOverTaskID=taskID
      // console.log("taskMouseEnter")
    },
    taskMouseLeave() {
      // console.log("taskMouseLeave")
      this.mouseOverTaskID=0
    },
    hasTaskDone(task) {
      return task && task.taskStatus == 2
    },
    isStatusTodo(task) {
      return task && task.taskStatus == 1
    },
    onDragStart(evt) {
      console.log("onDragStart: ", evt, evt.item.dataset.taskid)
      this.dragNoteIndexOfTaskID = evt.item.dataset.groupindex
    },
    async onDragEnd(evt) {
      if (this.mouseOverNoteID) {
        // 拖动到Note中
        let res = await axpost('/api/todo/task/chgnote', {
            taskID: parseInt(evt.item.dataset.taskid),
            newNoteID: parseInt(this.mouseOverNoteID),
          },{
            failMsg: "拖动任务失败",
          })
        console.log("chgnote res:", res)
        if (!axok(res)) {
          return
        }
        this.freshTasks()
      } else {
        if (currNote.noteID < 50) {
          return
        }
        // 任务之间移动
        if (evt.newIndex == evt.oldIndex) {
          return
        }
        let nextTaskID, prevTaskID = 0
        let todoTasks = this.groupTasks[evt.item.dataset.groupindex].tasks
        if (todoTasks.length - 1 > evt.newIndex) {
          console.log("onDragEnd: after note:", todoTasks[evt.newIndex + 1])
          nextTaskID = todoTasks[evt.newIndex + 1].taskID
        } else {
          if (todoTasks.length > 1) {
            console.log("onDragEnd: before note:", todoTasks[todoTasks.length - 2])
            prevTaskID = todoTasks[evt.newIndex - 1].taskID
          } else {
            return
          }
        }
        let currNote = this.$route.query
        let res = await axpost('/api/todo/task/chgorder', {
            taskID: parseInt(evt.item.dataset.taskid),
            noteID: parseInt(currNote.noteID),
            nextTaskID: nextTaskID,
            prevTaskID: prevTaskID,
          },{
            failMsg: "拖动任务失败",
          })
        console.log("chgorder res:", res)
        if (!axok(res)) {
          return
        }
        this.freshTasks()
      }
    },
    onDragMove() {
    // onDragMove(evt, originalEvent) {
      // console.log("onDragMove: ", evt, originalEvent)
      let group = this.groupTasks[this.dragNoteIndexOfTaskID]
      if (group.taskStatus != 1) {
        return false
      }
      // console.log("onDragMove222: ", evt, originalEvent, this.mouseOverNoteID)
      if (this.mouseOverNoteID) {
        return false
      }
    },
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.task-view{
  height: calc(100vh - 50px);
  background: #FFF;
  font-size:0.95rem;
  /* overflow-y: hidden; */
}
.gou{
	width: 0.2rem;
	height: 0.4rem;
	border-right:1px solid;
	border-bottom:1px solid;
	transform: rotate(45deg);
  margin-top: -0.1rem;
  /* margin-left:0.1rem; */
  /* margin: -0.1rem 0 0.1rem 0rem; */
  color:#797D7F;
}

.cycle{
  border: 1px solid #797D7F;
  /* border: 2px solid #f39800; */
  border-radius: 50%;
  width:0.8rem;
	height: 0.8rem;
  /* scale: 0.6; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.one-task{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.2rem;
  /* margin: 2px 10px 2px 20px; */
  /* padding: 0px 20px 0px 40px; */
  margin: 0px 10px 0px 20px;
  padding: 0px 6px 0px 20px;
}

.one-task-curr{
  background: #f4f6ff;
}
.one-task-mouseover{
  background: #F8F9F9;
}
.one-task-left{
  display: flex;
  justify-content: space-start;
  align-items: center;
}
.one-task-info-tip{
  font-size: 0.8rem;
  width:7rem;
  margin-right: 10px;
}
.one-task-info-endplan{
  color: blue;
}
.one-task-info-tip-past {
  color:red;
}
.task-cycle-pos{
  margin-right: 20px;
}
.task-title-view{
  display: flex;
  /* align-items: baseline; */
  align-items: center;
}

.task-cycle-star{
  border: 1px solid red;
}
.group-row{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  margin:20px;
}
.group-row-notfirst{
  margin-top:30px;
}
.group-row-info{
  display: flex;
}

.fold-css{
  width: 0.5rem;
  height: 0.5rem;
  border-top: 2px solid #808B96;
  border-right: 2px solid #808B96;
  transform: rotate(45deg);
  margin: 0 10px 0 15px;
}

.unfold-css{
  width: 0.5rem;
  height: 0.5rem;
  border-bottom: 2px solid #808B96;
  border-right: 2px solid #808B96;
  transform: rotate(45deg);
  margin: -0.2rem 10px 0 20px;
}

.new-task-css{
  margin: 20px 20px 20px 30px;
  width: 40%;
  padding: 5px;
}
/* .new-task-css>>>.el-input__inner {
    border: 0;
} */

.query-more {
    margin: 20px;
}

.task-detail {
  background: #F8F9F9;
  margin:0px;
  min-height: 100%;
}
.task-detail-sub{
  background:#FFF;
  margin:10px 8px 10px 8px;
  padding:10px;
  /* min-height: 100%; */
}

.task-detail-end-time{
  /* display: flex; */
  /* justify-content: space-between; */
  /* align-items: center; */
  background:#FFF;
  margin:0px 8px 10px 8px;
  padding:10px 10px 10px 10px;
}
.task-detail-plan-row{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background:#FFF;
  margin:10px 8px 10px 8px;
  padding:10px;
}
.task-plan-tip{
  display: flex;
  margin:2px 20px 2px 0px;
}
.task-plan-tip-future{
  color: blue;
}
.task-plan-tip-past{
  color: red;
}

.close-css {
    position: relative;
    width: 0.8rem;
    height: 0.8rem;
    margin-right: 12px;
}

.close-css::before,
.close-css::after {
    position: absolute;
    content: ' ';
    background-color: blue;
    left: 0.4rem;
    width: 1px;
    height: 0.8rem;
}

.close-css::before {
    transform: rotate(45deg);
}

.close-css::after {
    transform: rotate(-45deg);
}
/* .set-plan-time-button {
    position: relative !important;
}
.el-date-editor--datetime{
    background-color: darkblue !important;
    width: auto !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    opacity: 0;
} */

/*被拖拽对象的样式*/
.draged-item {
  padding: 6px;
  background-color: #fdfdfd;
  border: solid 1px #eee;
  margin-bottom: 10px;
  cursor: move;
} 
/*选中样式*/
.chosen {
  border: solid 2px #3089dc !important;
}

.set-top{
  padding:5px;
  font-size:1rem;
  color:#797D7F;
}
.set-top-active{
  padding:5px;
  font-size:1rem;
  background: #3498DB;
}
.do-time-button{
  padding:2px;
  border: none;
}
.today-tip{
  display: flex;
  /* justify-content: center; */
  align-items: center;

  color:#808B96;
  border:solid 1px ;
  border-radius: 30%;
  font-size:0.1rem;
  width:0.8rem;
	height: 0.8rem;
  margin-right:5px;
}
.week-tip{
  display: flex;
  justify-content: center;
  align-items: center;

  color:#808B96;
  border:solid 1px ;
  border-radius: 0.3rem;
  font-size:0.1rem;
  width:1.8rem;
	height: 0.8rem;
  margin-right:5px;
}

.plan-date-set{
  margin: 10px 0px 10px 0px;
}

.today-time-hand{
  cursor: pointer;
}

</style>
